import React, { useEffect } from 'react';
import { useAuth } from '@micro-stacks/react';
import { useDispatch } from 'react-redux';
import {
  fetchAssets2,
  fetchBalances,
  fetchMachineBalances,
  setCoreApiInfo,
} from '../features/connect/actions';
import { actionTypes } from '../features/connect';
import { useUser } from '@micro-stacks/react';
// import { toast } from 'react-toastify';

export const WalletConnectButton = () => {
  const { isSignedIn, handleSignIn, handleSignOut, isLoading } = useAuth();
  const data = useUser();
  const dispatch = useDispatch();

  const onClickFunction = async () => {
    if (isSignedIn) {
      handleSignOut();
      dispatch({ type: actionTypes.DISCONNECT });
    } else {
      const initialData = await handleSignIn();
      dispatch({ type: actionTypes.FETCH_DATA, data: initialData });
      dispatch(fetchBalances);
      dispatch(fetchAssets2);
      // toast.info(`Welcome`);
    }
    dispatch(fetchMachineBalances);
    dispatch(setCoreApiInfo);
  };

  useEffect(() => {
    dispatch(fetchMachineBalances);
    if (data.currentStxAddress) {
      dispatch({ type: actionTypes.FETCH_DATA, data });
      dispatch(fetchBalances);
      dispatch(fetchAssets2);
    }
    dispatch(setCoreApiInfo);
  }, []);

  return (
    <a onClick={() => onClickFunction()} className="btn-small z-depth-0">
      {isLoading
        ? 'Loading...'
        : isSignedIn
        ? 'Sign out'
        : 'Connect Stacks Wallet'}
    </a>
  );
};
