import React from 'react';

export const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row">
          <div className="col s12 m6">
            <h5 className="white-text">StacksPops</h5>
            <p className="grey-text text-lighten-4">Find your perfect pop.</p>
          </div>
          <div className="col s12 m6">
            <h5 className="white-text">Marketplaces</h5>
            <ul>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://stxnft.com/collections/SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-pops"
                  target="_blank"
                >
                  STXNFT
                </a>
              </li>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://www.stacksart.com/collections/stacks-pops/market"
                  target="_blank"
                >
                  STACKSART
                </a>
              </li>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://byzantion.xyz/marketplace/stacks-pops"
                  target="_blank"
                >
                  BYZANTION
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <a className="grey-text text-lighten-4 right">WAGMI</a>
        </div>
      </div>
    </footer>
  );
};
