import React from 'react';
import Lottie from 'lottie-react';

export const Empty = (props: {
  title: String;
  desc: String;
  action: Boolean;
  animation: any;
}) => {
  const style = {
    height: 170,
    width: 170,
    margin: 'auto',
  };

  return (
    <div className="empty-bloc">
      <Lottie animationData={props.animation} style={style} loop={true} />
      <span className="empty-title">{props.title}</span>
      <span className="empty-desc">{props.desc}</span>
      {props.action && (
        <a
          className="btn-blue"
          href="https://www.stacksart.com/collections/stacks-pops/market"
          target="_blank"
        >
          {'BUY MORE STACKSPOPS'}
        </a>
      )}
    </div>
  );
};
