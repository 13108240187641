import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../features/connect';
// import ProgressBar from '@ramonak/react-progress-bar';
import CountUp from 'react-countup';
import config from '../config';

export const MachineData = () => {
  const balances = useSelector(selectors.getMachineBalances);
  return (
    <div className="machine-data-bloc">
      <div className="balance-bloc">
        <div className="top">
          <div className="currency" id="ice">
            <CountUp
              className="value"
              start={0}
              end={Number(
                balances.fungible_tokens[
                  `${config.iceTokenContractAddress}::${config.iceAssetName}`
                ].balance
              )}
              duration={2}
            />
            <div className="currency-value">
              <img src="ice.svg" className="currency-logo-ice" />
              <a className="ticker">ICE</a>
            </div>
          </div>
        </div>
        <div className="bottom valign-wrapper">
          <a className="ticker">
            <b>
              {
                balances.non_fungible_tokens[
                  `${config.stacksPopsContractAddress}::${config.stacksPopsAssetName}`
                ].count
              }
              /10000
            </b>{' '}
            pops are in the freezer
          </a>
        </div>
      </div>
    </div>
  );
};
