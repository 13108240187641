import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { devToolsEnhancer } from 'redux-devtools-extension'
import { ConnectReducer } from './features/connect';

const createLogger = require('redux-logger').createLogger;

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  user: ConnectReducer,
});

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({});
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
