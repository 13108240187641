import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../features/connect';
import config from '../config';
import CountUp from 'react-countup';
const lodash = require('lodash');

export const Balance = () => {
  const balances = useSelector(selectors.getBalances);
  const data = useSelector(selectors.getData);
  const address = lodash.get(data, `addresses.${config.env}`);

  const truncate = (fullStr: string, strLen: number, separator: string) => {
    if (!fullStr) return '...';
    if (fullStr.length <= strLen) return fullStr;
    separator = separator || '...';
    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  };

  return (
    <div className="balance-bloc">
      <div className="top">
        <div className="currency" id="stx">
          <CountUp
            className="value"
            start={0}
            end={Math.floor(Number(balances.stx.balance) / 1000000)}
            duration={2}
          />
          <div className="currency-value">
            <img src="stx.webp" className="currency-logo-stx" />
            <a className="ticker">STX</a>
          </div>
        </div>
        <div className="currency" id="ice">
          <CountUp
            className="value"
            start={0}
            end={Number(
              balances.fungible_tokens[
                `${config.iceTokenContractAddress}::${config.iceAssetName}`
              ].balance
            )}
            duration={2}
          />
          <div className="currency-value">
            <img src="ice.svg" className="currency-logo-ice" />
            <a className="ticker">ICE</a>
          </div>
        </div>
      </div>
      <div className="bottom valign-wrapper">
        <a
          href={`https://explorer.stacks.co/address/${address}?chain=mainnet`}
          target="_blank"
          className="address center-align"
        >
          {address ? (
            truncate(address, 20, '...')
          ) : (
            <i className="material-icons">person_add_disabled</i>
          )}
        </a>
      </div>
    </div>
  );
};
