import React from 'react';
import { useLocation } from 'react-router-dom';
import { WalletConnectButton } from './WalletConnectButton';
import { NavLink } from 'react-router-dom';

export const Navbar = () => {
  const location = useLocation();
  const navId = location.pathname === '/' ? 'nav' : 'nav-white';
  const logoId = location.pathname === '/' ? 'logo-white' : 'logo';
  const navIconId = location.pathname === '/' ? 'nav-icon-white' : 'nav-icon';

  return (
    <div>
      <nav className="nav-wrapper z-depth-0" id={navId}>
        <div className="container">
          <NavLink to="/" className="brand-logo" id={logoId}>
            <span>
              Ice machine <b>V1</b>
            </span>
          </NavLink>
          <ul id="nav-mobile" className="right hide-on-med-and-down">
            <li>
              <WalletConnectButton />
            </li>
          </ul>
          <a href="#" data-target="slide-out" className="sidenav-trigger">
            <i className="material-icons" id={navIconId}>
              menu
            </i>
          </a>
        </div>
      </nav>

      <ul id="slide-out" className="sidenav">
        <li>
          <NavLink to="/" className="brand-logo">
            <b>Stacks</b>pops
          </NavLink>
        </li>
        <li>
          <WalletConnectButton />
        </li>
      </ul>
    </div>
  );
};
