import { StacksMainnet } from 'micro-stacks/network';

const env = 'mainnet'; // const mode = 'testnet';
const version = 'v1';
const deployerAddress = 'SP277HZA8AGXV42MZKDW5B2NNN61RHQ42MTAHVNB1';

/*const stacksPopsContractName = `stacks-pops-${'v8'}`; // SUPPR
const stacksPopsContractAddress = `${deployerAddress}.stacks-pops-${'v8'}`; // SUPPR
const stacksPopsAssetName = `stacks-pops`; // SUPPR*/

const stacksPopsContractName = `stacks-pops`;
const stacksPopsContractAddress = `SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG.stacks-pops`;
const stacksPopsAssetName = 'stacks-pops';

const iceMachineContractAddress = `${deployerAddress}.stacks-pops-ice-machine-${version}`;
const iceMachineContractName = `stacks-pops-ice-machine-${version}`;

const iceTokenContractName = `stacks-pops-ice-${version}`;
const iceTokenContractAddress = `${deployerAddress}.stacks-pops-ice-${version}`;
const iceAssetName = 'ice';

const frozenPopsContractName = `frozen-stacks-pops-${version}`;
const frozenPopsContractAddress = `${deployerAddress}.frozen-stacks-pops-${version}`;
const frozenPopsAssetName = 'frozen-stacks-pops';

const socketClientURI = 'wss://stacks-node-api.testnet.stacks.co';
//const socketClientURI = `wss://${env}-api.stackspops.club/`;
const apiURI = 'https://stacks-node-api.mainnet.stacks.co';

const minFreezingBlock = 1000;
const icePerPopPerBlock = 1;
const heatWaveMinBalance = 1618;
const heatMeltRate = 4;
const heatRewardRate = 1;


// import { StacksMainnet } from 'micro-stacks/network';
// import { useUser } from "@micro-stacks/react";

//const network = new StacksMainnet({ url: apiURI });
const network = new StacksMainnet();
// const { addresses } = useUser();

const config = {
  network,
  iceMachineContractAddress,
  iceMachineContractName,
  iceTokenContractAddress,
  iceTokenContractName,
  iceAssetName,
  stacksPopsContractName,
  stacksPopsContractAddress,
  stacksPopsAssetName,
  frozenPopsAssetName,
  frozenPopsContractAddress,
  frozenPopsContractName,
  deployerAddress,
  socketClientURI,
  apiURI,
  minFreezingBlock,
  icePerPopPerBlock,
  heatWaveMinBalance,
  heatMeltRate,
  heatRewardRate,
  env,
};

export default config;
