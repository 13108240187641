/* eslint-disable @typescript-eslint/default-param-last */
const lodash = require('lodash');

import {
  FETCH_ASSETS,
  DISCONNECT,
  FETCH_DATA,
  FETCH_BALANCES,
  FETCH_MACHINE_BALANCES,
  FETCH_API_INFO,
  ADD_TO_EARNINGS,
} from './actionTypes';
import { LoginActionTypes } from './types';
import config from '../../config';

const initialState = {
  earnings: 0,
  api_info: {
    stacks_tip_height: 0,
  },
  data: {
    addresses: {
      tesnet: '',
      mainnet: '',
    },
  },
  assets: [],
  balances: {
    stx: {
      balance: '0',
    },
    fungible_tokens: {
      [`${config.iceTokenContractAddress}::${config.iceAssetName}`]: {
        balance: '0',
      },
    },
  },
  machine_balances: {
    stx: {
      balance: '0',
    },
    fungible_tokens: {
      [`${config.iceTokenContractAddress}::${config.iceAssetName}`]: {
        balance: '0',
      },
    },
    non_fungible_tokens: {
      [`${config.stacksPopsContractAddress}::${config.stacksPopsAssetName}`]: {
        count: '0',
      },
    },
  },
};

export default (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case FETCH_ASSETS:
      return { ...state, assets: action.assets };
    case DISCONNECT:
      return { ...initialState };
    case FETCH_DATA:
      return { ...state, data: action.data };
    case FETCH_BALANCES:
      const iceBalancePath = `balances.fungible_tokens["${config.iceTokenContractAddress}::${config.iceAssetName}"].balance`;
      if (!lodash.get(action, iceBalancePath)) {
        lodash.set(action, iceBalancePath, '0');
      }
      return { ...state, balances: action.balances };
    case FETCH_MACHINE_BALANCES:
      const machineIceBalancePath = `machine_balances.fungible_tokens["${config.iceTokenContractAddress}::${config.iceAssetName}"].balance`;
      const machinePopsCountPath = `machine_balances.non_fungible_tokens["${config.stacksPopsContractAddress}::${config.stacksPopsAssetName}"].count`;
      if (!lodash.get(action, machineIceBalancePath)) {
        lodash.set(action, machineIceBalancePath, '0');
      }
      if (!lodash.get(action, machinePopsCountPath)) {
        lodash.set(action, machinePopsCountPath, '0');
      }
      return { ...state, machine_balances: action.machine_balances };
    case FETCH_API_INFO:
      return { ...state, api_info: action.info };
    case ADD_TO_EARNINGS:
      const earnings = state.earnings + action.earning;
      return { ...state, earnings };
    default:
      return state;
  }
};
