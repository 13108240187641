import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Machine } from './pages/Machine';
const M = require('materialize-css/dist/js/materialize.min.js');
import { Footer } from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastify.css';

const App: React.FC = () => {
  useEffect(() => {
    const sidenav = document.querySelector('#slide-out');
    M.Sidenav.init(sidenav, {});
    M.AutoInit();
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <main>
        <Switch>
          <Route path="/" component={Machine} exact />
        </Switch>
      </main>
      <Footer />
      <ToastContainer autoClose={10000} limit={5} />
    </BrowserRouter>
  );
};

export default App;
