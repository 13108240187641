import React, { useState, useRef, Fragment } from 'react';
import { useContractCall } from '@micro-stacks/react';
import { uintCV, listCV } from 'micro-stacks/clarity';
import {
  PostConditionMode,
  makeContractNonFungiblePostCondition,
  makeContractFungiblePostCondition,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  FungibleConditionCode,
  createAssetInfo,
} from 'micro-stacks/transactions';
import { PopCard } from './PopCard';
import config from '../config';
import { selectors } from '../features/connect';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { Empty } from './Empty';
import { listenTx } from '../features/connect/actions';
import * as winter from '../animations/winter.json';
import * as cold from '../animations/cold.json';
import Lottie from 'lottie-react';
const lodash = require('lodash');

export const MyFrozenPops = () => {
  const [selected, updateSelected] = useState([] as string[]);
  const [number, updateNumber] = useState(0);
  const [earnings, updateEarnings] = useState(0);
  const actions = useRef() as React.MutableRefObject<HTMLDivElement>;
  const dispatch = useDispatch();
  const userData = useSelector(selectors.getData);
  const userAddress = lodash.get(userData, `addresses.${config.env}`);

  const addToSelected = (id: string) => {
    selected.push(id);
    if (selected.length > 0) {
      actions.current.classList.add('m-fadeIn');
      actions.current.classList.remove('m-fadeOut');
    }
    updateSelected(selected);
    updateNumber(selected.length);
  };

  const removeFromSelected = (id: string) => {
    const index = selected.indexOf(id);

    if (index !== -1) {
      selected.splice(index, 1);
    }
    if (selected.length === 0) {
      actions.current.classList.add('m-fadeOut');
      actions.current.classList.remove('m-fadeIn');
    }

    updateSelected(selected);
    updateNumber(selected.length);
  };

  const addToEarnings = (value: number) => {
    updateEarnings(earnings + value);
  };

  const postConditions: any = [];

  postConditions.push(
    makeContractFungiblePostCondition(
      config.deployerAddress,
      config.iceMachineContractName,
      FungibleConditionCode.GreaterEqual,
      earnings,
      createAssetInfo(
        config.deployerAddress,
        config.iceTokenContractName,
        config.iceAssetName
      )
    )
  );

  selected.map((uint) => {
    postConditions.push(
      makeContractNonFungiblePostCondition(
        config.deployerAddress,
        config.iceMachineContractName,
        NonFungibleConditionCode.DoesNotOwn,
        createAssetInfo(
          'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG',
          config.stacksPopsContractName,
          config.stacksPopsAssetName
        ),
        uintCV(BigInt(uint))
      )
    );
  });

  selected.map((uint) => {
    postConditions.push(
      makeStandardNonFungiblePostCondition(
        userAddress,
        NonFungibleConditionCode.DoesNotOwn,
        createAssetInfo(
          config.deployerAddress,
          config.frozenPopsContractName,
          config.frozenPopsAssetName
        ),
        uintCV(BigInt(uint))
      )
    );
  });

  const { handleContractCall, isLoading } = useContractCall({
    contractAddress: config.deployerAddress,
    contractName: config.iceMachineContractName,
    functionName: 'defrost-many',
    functionArgs: [listCV(selected.map((uint) => uintCV(BigInt(uint))))],
    network: config.network,
    postConditionMode: PostConditionMode.Deny,
    postConditions: postConditions,
    onFinish: (data) => {
      dispatch(listenTx(data.txId, 'Defrost pops'));
    },
  });

  const assets = useSelector(selectors.getAssets).filter(
    (asset: any) =>
      asset.asset_identifier ===
      `${config.frozenPopsContractAddress}::${config.frozenPopsAssetName}`
  );

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const totalEarnings = useSelector(selectors.getEarnings);

  return (
    <Fragment>
      <Modal>
        <div className="confirmation-modal valign-wrapper">
          <h6>You are about to defrost {selected.length} pops</h6>
          <p>Once your pops are defrosted, you will be rewarded with $ICE.</p>
          <a onClick={close} className="btn-outlined">
            CANCEL
          </a>
          <a
            onClick={() => {
              handleContractCall();
              close();
            }}
            className="btn-blue"
          >
            LET'S GO
          </a>
        </div>
      </Modal>
      <div className="center my-pops-bloc">
        <div className="frozen-top">
          <Lottie
            animationData={cold}
            style={{
              height: 40,
            }}
            loop={true}
          />
          <div className="earnings">
            <a className="earnings">Actual earnings: {totalEarnings} $ICE</a>
          </div>
        </div>
        <div className="pops">
          {assets.length > 0 ? (
            assets.map((result: any, i: any) => {
              return (
                <PopCard
                  key={i}
                  add={addToSelected}
                  remove={removeFromSelected}
                  id={result.value.repr.substring(1)}
                  type={'frozen'}
                  scale={80}
                  select={true}
                  addToEarnings={addToEarnings}
                />
              );
            })
          ) : (
            <Empty
              title={'No pop in the freezer'}
              desc={'Freeze some pops to earn $ICE.'}
              action={false}
              animation={winter}
            />
          )}
        </div>
      </div>
      <div
        ref={actions}
        className="m-fadeOut shadow my-pops-actions valign-wrapper"
      >
        <div className="earnings">
          <a> Estimate: {earnings} $ICE </a>
        </div>
        <div>
          <a onClick={open} className="btn-blue">
            {isLoading ? 'Loading...' : `DEFROST ${number} POPS`}
          </a>
        </div>
      </div>
    </Fragment>
  );
};
