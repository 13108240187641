import React, { Fragment } from 'react';
import { MyPops } from '../components/MyPops';
import { MyFrozenPops } from '../components/MyFrozenPops';
import { Balance } from '../components/Balance';
// import { MintPop } from '../components/MintPop';
import { MachineData } from '../components/MachineData';

export const Machine = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col s12 m4">
              <h5 className="title-main">Welcome</h5>
              <div className="shadow" id="discord">
                <div id="discord-background">
                  <h6 className="subtitle"> Get the latest updates </h6>
                  <a className="description"> Join our discord </a>
                  <div
                    onClick={() =>
                      window.open('https://discord.gg/FnMeKT3Jy3', '_blank')
                    }
                    className="arrow"
                  >
                    <i className="material-icons">arrow_forward</i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col s12 m4">
              <h5 className="title-main">How it works</h5>
              <div className="shadow" id="explanation">
                <div id="explanation-background">
                  <h6 className="subtitle"> It’s raining ICE! </h6>
                  <a className="description"> See how you can earn $ICE. </a>
                  <div className="arrow">
                    <i className="material-icons">arrow_forward</i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col s12 m4">
              <h5 className="title-main">Balances</h5>
              <div className="shadow" id="balance">
                <Balance />
              </div>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="section">
          <div className="row">
            <div className="col s12 m7">
              <h5 className="title-main">Freeze your pops</h5>
              <h6 className="title-desc">MY POPS</h6>
              <div className="freeze shadow">
                <div className="freeze-background"></div>
                <MyPops />
              </div>
            </div>

            <div className="col s12 m5">
              <h5 className="title-main">In the freezer</h5>
              <h6 className="title-desc">FROZEN POPS</h6>
              <div className="frozen shadow">
                <MyFrozenPops />
              </div>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="section">
          <div className="row">
            <div className="col s12 m8">
              <h5 className="title-main"></h5>
              <h6 className="title-desc"></h6>
            </div>
            <div className="col s12 m4">
              <h5 className="title-main">Data</h5>
              <h6 className="title-desc">IN THE FREEZER</h6>
              <MachineData />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
