import { fetchAccountBalances, fetchCoreApiInfo } from 'micro-stacks/api';
import {
  FETCH_ASSETS,
  FETCH_BALANCES,
  FETCH_MACHINE_BALANCES,
  FETCH_API_INFO,
} from './actionTypes';
import config from '../../config';
import { connectWebSocketClient } from '@stacks/blockchain-api-client';
import { toast } from 'react-toastify';

export const setCoreApiInfo = async (dispatch: any) => {
  const info: any = await fetchCoreApiInfo({
    url: config.network.getCoreApiUrl(),
  });
  dispatch({ type: FETCH_API_INFO, info });
};

const fetchAssetPage = async (
  getState: any,
  offset = 0,
  limit = 200,
  assetsArray: any = []
): Promise<any> => {
  const filter1 = `${config.stacksPopsContractAddress}::${config.stacksPopsAssetName}`;
  const filter2 = `${config.frozenPopsContractAddress}::${config.frozenPopsAssetName}`;
  const response = await fetch(
    `${config.apiURI}/extended/v1/tokens/nft/holdings?principal=${
      getState().user.data.addresses[config.env]
    }&limit=${limit}&offset=${offset}&asset_identifiers=${filter1}&asset_identifiers=${filter2}&unanchored=false`
  );
  const assets = await response.json();
  if (offset > assets.total) {
    return assetsArray;
  }
  return fetchAssetPage(
    getState,
    offset + limit,
    limit,
    assetsArray.concat(assets.results)
  );
};

export const fetchAssets = async (dispatch: any, getState: any) => {
  const results = await fetchAssetPage(getState);

  const filter = results.filter(
    (asset: any) =>
      asset.event_type === 'non_fungible_token_asset' &&
      asset.asset.recipient === getState().user.data.addresses[config.env] &&
      (asset.asset.asset_id ===
        `${config.stacksPopsContractAddress}::${config.stacksPopsAssetName}` ||
        asset.asset.asset_id ===
          `${config.frozenPopsContractAddress}::${config.frozenPopsAssetName}`)
  );

  const mapAsset: Record<string, any> = {};
  filter.forEach((asset: any) => {
    if (
      !mapAsset[asset.asset.value.repr] ||
      mapAsset[asset.asset.value.repr] < asset.event_index
    )
      mapAsset[asset.asset.value.repr] = asset;
  });
  dispatch({ type: FETCH_ASSETS, assets: Object.values(mapAsset) });
};

export const fetchAssets2 = async (dispatch: any, getState: any) => {
  const results = await fetchAssetPage(getState);
  dispatch({ type: FETCH_ASSETS, assets: results });
};

export const fetchBalances = async (dispatch: any, getState: any) => {
  const balances = await fetchAccountBalances({
    url: config.network.getCoreApiUrl(),
    principal: getState().user.data.addresses[config.env],
  });
  dispatch({ type: FETCH_BALANCES, balances });
};

export const fetchMachineBalances = async (dispatch: any) => {
  const machineBalances = await fetchAccountBalances({
    url: config.network.getCoreApiUrl(),
    principal: config.iceMachineContractAddress,
  });
  dispatch({ type: FETCH_MACHINE_BALANCES, machine_balances: machineBalances });
};

export const listenTx = (tx: string, name: string) => {
  toast.info(`${name} transaction has been sent`);
  return async function (dispatch: any) {
    const client = await connectWebSocketClient(config.socketClientURI);
    client.subscribeTxUpdates(tx, async (event) => {
      toast.info(`${name} status is ${event.tx_status}`);
      dispatch(fetchBalances);
      dispatch(fetchMachineBalances);
      dispatch(fetchAssets2);
    });
  };
};
