import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../features/connect';
import config from '../config';
import { uintCV } from 'micro-stacks/clarity';
import { fetchReadOnlyFunction } from 'micro-stacks/api';
const moment = require('moment');
import { actionTypes } from '../features/connect';
import { useDispatch } from 'react-redux';

export function PopCard(props: {
  add?: Function;
  remove?: Function;
  id: String;
  type: String;
  scale: number;
  select: Boolean;
  addToEarnings?: Function;
  addToTotalEarnings?: Function;
}) {
  const dispatch = useDispatch();
  const totalEarnings = useSelector(selectors.getEarnings);
  const apiInfo = useSelector(selectors.getCoreApiInfo);
  const mask = useRef() as React.MutableRefObject<HTMLDivElement>;
  const maskIce = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [isSelected, setSelected] = useState(false);
  const [isReady, setReady] = useState(false);
  const [isFrozen, setFrozen] = useState(false);
  const [earning, setEarning] = useState(0);
  const [timeFreeze, setTimeFreeze] = useState('');

  const onSelect = () => {
    const { id, add, remove, addToEarnings } = props;
    if (!add || !remove) return;
    setSelected(!isSelected);
    if (isSelected) {
      mask.current.classList.remove('mask-selected');
      mask.current.classList.remove('mask-selected-check');
      if (isFrozen && addToEarnings) addToEarnings(-earning);
      remove(id);
    } else {
      mask.current.classList.add('mask-selected');
      mask.current.classList.add('mask-selected-check');
      if (isFrozen && addToEarnings) addToEarnings(earning);
      add(id);
    }
  };

  const getFreezeBlock = async () => {
    const readOnlyFunction = await fetchReadOnlyFunction({
      network: config.network,
      contractAddress: config.deployerAddress,
      contractName: config.iceMachineContractName,
      functionArgs: [uintCV(Number(props.id))],
      functionName: 'get-freeze-block-height',
    });
    return readOnlyFunction;
  };

  useEffect(() => {
    // Create an scoped async function in the hook
    const getInfo = async () => {
      const block = await getFreezeBlock();
      const e = apiInfo.stacks_tip_height - Number(block);
      const ready =
        Number(block) > 0 &&
        apiInfo.stacks_tip_height > Number(block) + config.minFreezingBlock;
      setReady(ready);
      const freeze = moment(new Date())
        .subtract(e * 10.3, 'minutes')
        .fromNow();
      setTimeFreeze(freeze);
      setEarning(e);
      if (!totalEarnings && e > 0) {
        dispatch({ type: actionTypes.ADD_TO_EARNINGS, earning: e });
      }
    };
    if (props.type === 'frozen') {
      setFrozen(true);
      getInfo();
    }
  }, []);
  if (isReady) maskIce.current.classList.add('mask-ready');
  return (
    <div
      className="pop-card"
      onClick={() => {
        //if ((props.select && isReady) || !isFrozen) onSelect();
        if ((props.select && isReady) && isFrozen) onSelect();
      }}
      style={{
        height: props.scale,
        width: props.scale,
        backgroundImage: isFrozen
          ? `url(https://stackspops.infura-ipfs.io/ipfs/QmdqZxgqnhh1bVmzu6VZDkAgUeV44qxhAyUc6dnbveq6FQ/${props.id}.png)`
          : `url(https://stackspops.infura-ipfs.io/ipfs/QmPAenbtyzFXCYWnmbcp1gRoJeH229nr4y37Gb32ePLSv6/${props.id}.png)`,
        backgroundSize: 'contain',
      }}
    >
      <div ref={mask} className="mask">
        <div ref={maskIce} className="sub-mask">
          {isFrozen && (
            <div className="time-left">
              {timeFreeze} <br></br>
              {earning} $ICE
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
