import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import { MicroStacksProvider } from '@micro-stacks/react';
import './index.css';
import App from './App';
import config from './config';

const authOptions = {
  network: config.network,
  appDetails: {
    name: 'Stacks Pops',
    icon: 'https://stackspops.club/app-icon.png',
  },
};

ReactDOM.render(
  <Provider store={store}>
    <MicroStacksProvider authOptions={authOptions}>
      <App />
    </MicroStacksProvider>
  </Provider>,
  document.getElementById('root')
);
