import React, { useState, useRef, Fragment } from 'react';
import { useContractCall } from '@micro-stacks/react';
import { uintCV, listCV } from 'micro-stacks/clarity';
import {
  PostConditionMode,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  createAssetInfo,
} from 'micro-stacks/transactions';
import { PopCard } from './PopCard';
import { Empty } from './Empty';
import config from '../config';
import { selectors } from '../features/connect';
import { useSelector, useDispatch } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import * as empty from '../animations/empty.json';
import { listenTx } from '../features/connect/actions';
const lodash = require('lodash');
const moment = require('moment');

export const MyPops = () => {
  const [selected, updateSelected] = useState([] as string[]);
  const [number, updateNumber] = useState(0);
  const actions = useRef() as React.MutableRefObject<HTMLDivElement>;
  const freezeBtn = useRef() as React.MutableRefObject<HTMLAnchorElement>;
  const dispatch = useDispatch();
  const userData = useSelector(selectors.getData);
  const userAddress = lodash.get(userData, `addresses.${config.env}`);

  const addToSelected = (id: string) => {
    selected.push(id);
    if (selected.length > 0) {
      actions.current.classList.add('m-fadeIn');
      actions.current.classList.remove('m-fadeOut');
    }
    if (selected.length >= 3) {
      freezeBtn.current.classList.remove('disabled');
    }

    updateSelected(selected);
    updateNumber(selected.length);
  };

  const removeFromSelected = (id: string) => {
    const index = selected.indexOf(id);
    if (index !== -1) {
      selected.splice(index, 1);
    }
    if (selected.length === 0) {
      actions.current.classList.add('m-fadeOut');
      actions.current.classList.remove('m-fadeIn');
    }
    if (selected.length < 3) {
      freezeBtn.current.classList.add('disabled');
    }
    updateSelected(selected);
    updateNumber(selected.length);
  };

  const postConditions: any = [];
  selected.map((uint) => {
    postConditions.push(
      makeStandardNonFungiblePostCondition(
        userAddress,
        NonFungibleConditionCode.DoesNotOwn,
        createAssetInfo(
          'SPJW1XE278YMCEYMXB8ZFGJMH8ZVAAEDP2S2PJYG',
          config.stacksPopsContractName,
          config.stacksPopsAssetName
        ),
        uintCV(BigInt(uint))
      )
    );
  });

  const { handleContractCall, isLoading } = useContractCall({
    contractAddress: config.deployerAddress,
    contractName: config.iceMachineContractName,
    functionName: 'freeze-many',
    functionArgs: [listCV(selected.map((uint) => uintCV(BigInt(uint))))],
    network: config.network,
    postConditionMode: PostConditionMode.Deny,
    postConditions: postConditions,
    onFinish: (data) => {
      dispatch(listenTx(data.txId, 'Freeze pops'));
    },
  });

  const assets = useSelector(selectors.getAssets).filter(
    (asset: any) =>
      asset.asset_identifier ===
      `${config.stacksPopsContractAddress}::${config.stacksPopsAssetName}`
  );

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  return (
    <Fragment>
      <Modal>
        <div className="confirmation-modal valign-wrapper">
          <h6>You are about to freeze {selected.length} pops</h6>
          {/*<div className="pops-confirmation">
            {selected.map((id: any, i: any) => {
              return (
                <PopCard
                  key={i}
                  id={id}
                  type={'non-frozen'}
                  scale={50}
                  select={false}
                />
              );
            })}
          </div>*/}
          <p>
            Once your pops are frozen, you can defrost them after{' '}
            {moment
              .duration(config.minFreezingBlock * 10, 'minutes')
              .humanize()}{' '}
            ({config.minFreezingBlock} blocks).
          </p>
          <a onClick={close} className="btn-outlined">
            CANCEL
          </a>
          <a
            onClick={() => {
              handleContractCall();
              close();
            }}
            className="btn-blue"
          >
            LET'S GO
          </a>
        </div>
      </Modal>
      <div className="my-pops-bloc center valign-wrapper">
        <div className="pops">
          {assets.length > 0 ? (
            assets.map((result: any, i: any) => {
              return (
                <PopCard
                  key={i}
                  add={addToSelected}
                  remove={removeFromSelected}
                  id={result.value.repr.substring(1)}
                  type={'non-frozen'}
                  scale={80}
                  select={true}
                />
              );
            })
          ) : (
            <Empty
              title={'It’s a bit empty here '}
              desc={
                'Add some pops to your collection to use them with the Ice Machine.'
              }
              action={true}
              animation={empty}
            />
          )}
        </div>
      </div>
      <div ref={actions} className="my-pops-actions m-fadeOut shadow">
        <a ref={freezeBtn} onClick={open} className="btn-blue disabled">
          {isLoading ? 'Loading...' : `FREEZE ${number} POPS`}
          {number < 3 && (
            <span className="add-more"> ADD {3 - number} MORE</span>
          )}
          {number > 100 && (
            <span className="add-more"> REMOVE {number - 100} MORE</span>
          )}
        </a>
      </div>
    </Fragment>
  );
};
